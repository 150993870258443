<template>
  <div class="pad-b-3">
    <div class="hero-nosotros">
      <img
        src="../../assets/img/covers/nosotros-portada.jpg"
        width="100%"
        height="auto"
        srcset="
          ../../assets/img/covers/nosotros-portada-responsive.jpg 767w,
          ../../assets/img/covers/nosotros-portada.jpg
        "
        sizes="(min-width: 992px) 100vw"
        alt="Sobre Nosotros"
        class="hero-nosotros__cover"
      />

      <div class="bb-container hero-nosotros__container">
        <h1 class="hero-nosotros__h">{{ $t("aboutUs.aboutUs", lang) }}</h1>
        <h3 class="hero-nosotros__subh">
          {{ $t("aboutUs.title", lang) }}
        </h3>
      </div>
    </div>

    <div class="nosotros-claim">
      <div class="bb-container bb-container--730">
        <div class="nosotros-claim__content">
          <div class="nosotros-claim__ico">
            <img src="../../assets/img/ico/ico-custom-heart.png" alt="" />
          </div>
          <div class="nosotros-claim__copy">
            {{ $t("aboutUs.description", lang) }}
          </div>
        </div>
      </div>
    </div>

    <div class="nosotros-help">
      <div class="bb-container bb-container--730">
        <h3 class="nosotros-help__h">{{ $t("aboutUs.whatWeDo", lang) }}</h3>
      </div>
      <div class="bb-container">
        <div class="nosotros-help__items">
          <div class="nosotros-help__item" v-for="item in help" :key="item.title">
            <div class="nosotros-help__ico">
              <i class="uil uil-check"></i>
            </div>
            <div class="nosotros-help__content">
              <div class="nosotros-help__title">{{ item.title }}</div>
              <div class="nosotros-help__description">{{ item.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="nosotros-comienzo">
      <div class="bb-container bb-container--730">
        <h3 class="nosotros-comienzo__h">{{ $t("aboutUs.begginingTitle", lang) }}</h3>
        <h4 class="nosotros-comienzo__subh">
          {{ $t("aboutUs.begginingSubtitle", lang) }}
        </h4>
        <div class="nosotros-comienzo__body">
          <div class="post-body">
            <p>
              {{ $t("aboutUs.begginingP1", lang) }}
            </p>

            <p>
              {{ $t("aboutUs.begginingP2", lang) }}
            </p>

            <p>
              {{ $t("aboutUs.begginingP3", lang) }}
            </p>

            <p>
              <strong>
                {{ $t("aboutUs.begginingP4", lang) }}
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="nosotros-imagen">
      <div class="bb-container">
        <div class="nosotros-imagen__cover">
          <img src="../../assets/img/covers/nosotros-equipo.jpg" alt="" />
        </div>
      </div>
    </div>

    <div class="nosotros-conseguido">
      <div class="bb-container bb-container--730">
        <h3 class="nosotros-conseguido__h">{{ $t("aboutUs.currentNumbers", lang) }}</h3>
        <h4 class="nosotros-conseguido__subh">
          {{ $t("aboutUs.currentNumbersSubtitle", lang) }}
        </h4>
      </div>
      <div class="bb-container">
        <div class="nosotros-conseguido__items">
          <div class="nosotros-conseguido__item" v-for="item in milestones" :key="item.milestiones">
            <div class="nosotros-conseguido__title">
              {{ item.title }}
            </div>
            <div class="nosotros-conseguido__valor">{{ item.valor }}</div>
            <div class="nosotros-conseguido__copy">{{ item.description }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="nosotros-cta">
      <div class="bb-container">
        <div class="nosotros-cta__cols">
          <div class="nosotros-cta__left">
            <h3 class="nosotros-cta__titulo">{{ $t("aboutUs.doYouWantToJoinUs", lang) }}</h3>
            <div class="nosotros-cta__content">
              {{ $t("aboutUs.doYouWantToJoinUsDescription", lang) }}
            </div>
            <div class="nosotros-cta__actions">
              <a href="mailto:hola@hellobb.net" class="button button--primary"
                >{{ $t("aboutUs.doYouWantToJoinUsButton", lang) }}</a
              >
            </div>
          </div>
          <div class="nosotros-cta__right">
            <img src="../../assets/img/covers/nosotros-trabaja.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="nosotros-ubicacion">
      <div class="bb-container">
        <div class="nosotros-ubicacion__cols">
          <div class="nosotros-ubicacion__left">
            <h3 class="nosotros-ubicacion__titulo">¿Dónde estamos?</h3>
            <div class="nosotros-ubicacion__content">
              <i class="uil uil-map-marker"></i>
              <address>
                C/ Pompeu Fabra 36 <br />
                Tortosa (4500) <br />
                Tarragona, España
              </address>
            </div>
          </div>
          <div class="nosotros-ubicacion__right">
            <img src="../../assets/img/covers/nosotros-map-tortosa-full.jpg" alt="" />
          </div>
        </div>
      </div>
    </div> -->

    <div class="bb-container"></div>
  </div>
</template>
<script>
export default {
  name: "Nosotros",
  metaInfo() {
    return {
      title: this.$t("aboutUs.metatitle", this.lang),
      meta: [
        { name: "description", content: this.$t("aboutUs.metadescription", this.lang)}
      ],
      link: [
        { rel: "canonical", href: "https://www.hellobb.net/sobre-nosotros" },
        { rel: "alternate", href: "https://www.hellobb.net/qui-sommes-nous", hreflang: "fr" },
        { rel: "alternate", href: "https://www.hellobb.net/sobre-nosotros", hreflang: "es-es" },
        { rel: "alternate", href: "https://www.hellobb.net/sobre-nosotros", hreflang: "es-mx" },
        { rel: "alternate", href: "https://www.hellobb.net/sobre-nosotros", hreflang: "es-co" },
        { rel: "alternate", href: "https://www.hellobb.net/sobre-nosotros", hreflang: "es-ar" },
        { rel: "alternate", href: "https://www.hellobb.net/sobre-nosotros", hreflang: "es-cl" },
        { rel: "alternate", href: "https://www.hellobb.net/sobre-nosotros", hreflang: "es-uy" },
        { rel: "alternate", href: "https://www.hellobb.net/sobre-nosotros", hreflang: "es-pe" },
      ],
      __dangerouslyDisableSanitizers: ['title', 'meta'],
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": "HelloBB",
            "operatingSystem": [ //no estic segur del format per posar més d’un valor
              "ANDROID",
              "IOS",
            ],
            "applicationCategory": "LifestyleApplication",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.5", //no aconsegueixo veure ratings, tu pots?
              "ratingCount": "20"  //no aconsegueixo veure ratings, tu pots?
            },
            "offers": {
              "@type": "Offer",
              "price": "0",
            }
          }
        },
          {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "HelloBB",
            "legalName" : "HelloBB S.L.",
            "url": "https://www.hellobb.net",
            "logo": "https://www.hellobb.net/logo-hellobb-dark.svg", //posar URL on tenim el logo
            "description": "Pon en tu lista de nacimiento cualquier cosa de cualquier tienda.",
            "foundingDate": "2022",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "C/ Pompeu Fabra 36",
              "addressLocality": "Tortosa",
              "addressRegion": "Tarragona",
              "postalCode": "43500",
              "addressCountry": {
                "@type": "Country",
                "name": "Spain" //en anglés millor?
              }
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "contactType": "customer support",
              "email": "hola@hellobb.net"
            },
            "sameAs": [
              "https://www.instagram.com/holahellobebe/",
              "https://www.facebook.com/holahelloBB",
              "https://www.pinterest.es/holahelloBB/",
              "https://www.crunchbase.com/organization/hellobb",
            ]
          }
        }
      ]
    };
  },
  computed: {
    lang()  {
      return this.$route.meta?.lang || this.$i18n.locale;
    },
    help() {
      let lang = this.$route.meta?.lang || this.$i18n.locale;
      return [
        {
          title: this.$t("aboutUs.free", lang),
          description:
          this.$t("aboutUs.freeDescription", lang),
        },
        {
          title: this.$t("aboutUs.yourRithm", lang),
          description:
          this.$t("aboutUs.yourRithmDescription", lang)
        },
        {
          title: this.$t("aboutUs.personal", lang),
          description:
          this.$t("aboutUs.personalDescription", lang),
        },
        {
          title: this.$t("aboutUs.sensate", lang),
          description:
          this.$t("aboutUs.sensateDescription", lang),
        },
      ]
    },
    milestones() {
      let lang = this.$route.meta?.lang || this.$i18n.locale;
      return [
        {
          title: this.$t("aboutUs.helpMoreThan", lang),
          valor: "50.000",
          description: this.$t("aboutUs.helpMoreThan2", lang),
        },
        {
          title: this.$t("aboutUs.manageMoreThan", lang),
          valor: "15.000.000 €",
          description: this.$t("aboutUs.manageMoreThan2", lang),
        },
        {
          title: this.$t("aboutUs.helpMoreThan", lang),
          valor: "400.000",
          description: this.$t("aboutUs.helpMoreThan3", lang),
        },
      ]
    },
  },
};
</script>
